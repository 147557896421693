import {Article} from 'components/Article';
import {SEO} from 'components/SEO';
import {graphql, PageProps} from 'gatsby';
import {RichText} from 'prismic-reactjs';
import React from 'react';
import styled from 'styled-components';
import {htmlSerializer} from 'utils/htmlSerializer';
import {PageQuery} from '../../types/graphql-types';

const Image = styled.img`
  margin-bottom: 0;
`;

const Page: React.FC<PageProps<PageQuery>> = ({data, pageContext, path, uri}) => {
  const doc = data?.prismic?.allPages?.edges?.slice(0, 1).pop();

  if (!doc) return null;
  const page = doc.node;
  const imageUrl = page.image?.url;
  const title = RichText.asText(page.title);

  return (
    <>
      <SEO
        path={path}
        title={RichText.asText(page.meta_title || page.title)}
        description={RichText.asText(page.meta_description)}
        bodyAttributes={{class: 'blog'}}
        preview={imageUrl}
      />
      <Article>
        <RichText render={page.title} />
        {imageUrl && <Image src={imageUrl} alt={title} />}
        <RichText render={page.body} htmlSerializer={htmlSerializer} />
      </Article>
    </>
  );
};

export default Page;

export const query = graphql`
  query Page($uid: String, $lang: String) {
    locales: allLocale(filter: {ns: {in: ["common"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismic {
      allPages(uid: $uid, lang: $lang) {
        edges {
          node {
            title
            meta_title
            body
            image
            meta_description
            _meta {
              uid
              lang
              firstPublicationDate
            }
          }
        }
      }
    }
  }
`;
