import { AnchorLink } from "components/AnchorLink";
import { Link } from "gatsby-plugin-react-i18next";
import { Elements, HTMLSerializer } from "prismic-reactjs";
import React from "react";
import slugify from "slugify";
import { languages } from "../../languages";
import { BlogLink } from "components/links/Blog";
import { PrismicPage } from "components/links/PrismicPage";

const getNodeText = (node: React.ReactNode | React.ReactNode[]): string => {
  if (['string', 'number'].includes(typeof node)) return (node as unknown) as string;
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText((node as any).props.children);
  return '';
};

export const htmlSerializer: HTMLSerializer<React.ReactNode> = (
  type,
  children,
  element,
  content
) => {
  switch (type) {
    case Elements.heading1: {
      if (!content) return null;
      return <h1 id={slugify(getNodeText(content), {lower: true, strict: true})}>{content}</h1>;
    }
    case Elements.heading2: {
      if (!content) return null;
      return <h2 id={slugify(getNodeText(content), {lower: true, strict: true})}>{content}</h2>;
    }
    case Elements.heading3: {
      if (!content) return null;
      return <h3 id={slugify(getNodeText(content), {lower: true, strict: true})}>{content}</h3>;
    }
    case Elements.heading4: {
      if (!content) return null;
      return <h4 id={slugify(getNodeText(content), {lower: true, strict: true})}>{content}</h4>;
    }
    case Elements.heading5: {
      if (!content) return null;
      return <h5 id={slugify(getNodeText(content), {lower: true, strict: true})}>{content}</h5>;
    }
    case Elements.embed: {
      const embed = children.oembed;
      if (embed && embed.type === 'video') {
        return (
          <div
            className="videoWrapper"
            style={{paddingBottom: `${(embed.height / embed.width) * 100}%`}}
            dangerouslySetInnerHTML={{__html: children.oembed.html}}
          />
        );
      }
      return null;
    }
    case Elements.hyperlink: {
      let url = children.data.url;

      if (!content) return null;
      const moneiRegex = new RegExp(`^https:\\/\\/monei\\.com\\/(${languages.join('|')})\\/(\\S+)?`);
      const blogRegex = new RegExp(`/monei.com\/(${languages.join('|')}\/)?blog/`);
      const match = moneiRegex.exec(url);

      if (match) {
        const [, language, path = ''] = match;
        const url = `/${path.replace('online-payment-methods', 'payment-methods')}`
        return (
          <Link to={url} language={language}>
            {content}
          </Link>
        );
      }
      if (children.data.type === 'post') {
        return <BlogLink slug={children.data.slug}>{content}</BlogLink>;
      }
      if (children.data.type === 'page') {
        return <PrismicPage slug={children.data.slug}>{content}</PrismicPage>;
      }
      if (blogRegex.exec(url)) {
        url = children.data.url.split('/')
        const anchor = url.pop()
        return <BlogLink slug={url.pop()} anchor={anchor}>{content}</BlogLink>;
      }
      if (children.data.url?.startsWith('https://monei.com')) {
        return <Link to={url.replace('https://monei.com', '').replace('online-payment-methods/', 'payment-methods/')}>{content}</Link>;
      }

      const external = !url?.includes('monei.com');
      return (
        <AnchorLink external={external} href={children.data.url} target="_blank">
          {content}
        </AnchorLink>
      );
    }

    default: {
      return null;
    }
  }
};
